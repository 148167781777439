// extracted by mini-css-extract-plugin
export var bodyFont = "index-module--body-font--0kT3c";
export var borderRadius = "index-module--border-radius--7jiBs";
export var clearButtonStyles = "index-module--clear-button-styles--j8mnO";
export var header = "index-module--header--Bx44a";
export var isVisible = "index-module--isVisible--Zykic";
export var logo = "index-module--logo--zOb6w";
export var page_heading = "index-module--page_heading--4MBj3";
export var results_page = "index-module--results_page--pjp3b";
export var results_wrapper = "index-module--results_wrapper--OvhqD";
export var titleFont = "index-module--title-font--mIS-a";