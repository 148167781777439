import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Marker, ResultsMap } from "@components/ResultsMap"
import resultBlockUrl from "@utils/resultBlockUrl"
import priceRange from "@utils/priceRange"
import qs from "query-string"
import getMapCenter from "@utils/getMapCenter"

import * as styles from "./index.module.less"
import { useSelector } from "react-redux"

const mapCenters = [
  {
    city: "Toronto",
    center: { lat: 43.6508797, lng: -79.3834781 },
    zoom: 11,
  },
  {
    city: "Kitchener",
    center: { lat: 43.4185476, lng: -80.5133956 },
    zoom: 11,
  },
  {
    city: "Calgary",
    center: { lat: 51.0336616, lng: -114.0695579 },
    zoom: 11,
  },
  {
    city: "New York",
    center: { lat: 40.7167292, lng: -74.0420121 },
    zoom: 13,
  },
]

const Map = () => {
  const [desktop, setDesktop] = useState(false)
  const [center, setCenter] = useState({ lat: 43.6508797, lng: -79.3834781 })
  const [zoom, setZoom] = useState(11)

  const searchString =
    typeof window !== "undefined" ? window.location.search : ""

  const list = useSelector(state => state.results.list)
  const activeId = useSelector(state => state.results.activeId)
  const mapCenter = useSelector(state => state.results.mapCenter)
  const resultsLoaded = useSelector(state => state.results.resultsLoaded)

  const days = useSelector(state => state.profile.days)

  useEffect(() => {
    if (!list) return
    if (list.length === 0) return

    const center = {
      lat: list[0]?.locatoin?.lat,
      lng: list[0]?.locatoin?.lng,
    }

    setCenter(center)
  }, [list])

  useEffect(() => {
    toggleMap()
    window.addEventListener("resize", toggleMap)
    return () => {
      window.removeEventListener("resize", toggleMap)
    }
  }, [])

  useEffect(() => {
    const params = qs.parse(searchString)
    const mapData = mapCenters.find(i => i.city === params.location)
    if (mapData) {
      setCenter(mapData.center)
      setZoom(mapData.zoom || 11)
    }
  }, [searchString])

  const toggleMap = () => {
    setDesktop(typeof window !== "undefined" && window.innerWidth >= 1200)
  }

  if (!desktop) {
    return null
  }

  return (
    <div className={styles.map_container}>
      <div>
        <ResultsMap
          defaultCenter={mapCenter}
          defaultZoom={11}
          center={center}
          zoom={zoom}
        >
          {list &&
            list.length > 0 &&
            list.map(space => {
              if (!space.location || !resultsLoaded) {
                return null
              }
              return (
                <Marker
                  url={resultBlockUrl(space)}
                  lat={space.location.lat}
                  lng={space.location.lng}
                  text={
                    space.price_per_day_pass
                      ? `$${space.price_per_day_pass}/day`
                      : `$${Number(
                          priceRange(space).rangeStart * days
                        ).toLocaleString("en-US")}${
                          priceRange(space).multiple ? "+" : ""
                        }`
                  }
                  key={space.space_id}
                  selected={activeId === space.space_id}
                />
              )
            })}
        </ResultsMap>
      </div>
    </div>
  )
}

export default Map

Map.propTypes = {
  spaceUserId: PropTypes.string,
}
