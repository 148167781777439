import {
  SET_ACTIVE_SPACE,
  SET_HOLIDAYS,
  SET_RESULTS,
  SET_PROGRESS,
  INVALID_RESULTS,
  SET_OPERATING_HOURS,
} from "./types"
import request from "@services/request"
import { resTypes } from "@config"
import qs from "query-string"
import isUUID from "validator/es/lib/isUUID"
import notifyError from "../../utils/notifyError"

export const refreshResults = () => {
  return function (dispatch) {
    dispatch(setProgress())
    let params =
      typeof window !== "undefined" ? qs.parse(window.location.search) : {}
    // every time user searches, update the local storage values

    if (
      !process.env.GATSBY_CLIENT_UUID ||
      !isUUID(process.env.GATSBY_CLIENT_UUID, 4)
    ) {
      dispatch(setInvalidResults())
      return
    }

    const selectedType = resTypes.find(type => type.slug === params.res_type)
    const url = selectedType
      ? selectedType.search_endpoint
      : resTypes[0].search_endpoint

    request(url, {
      params,
      results_count: 1,
      space_operator_id: process.env.GATSBY_CLIENT_UUID,
    })
      .then(res => {
        if (res && res.status === "success" && res.scores) {
          dispatch(setResults(res.scores, res.user || {}))
        } else {
          dispatch(setInvalidResults())
        }
      })
      .catch(e => {
        notifyError(
          `Failed to get the results in ${
            typeof window !== "undefined"
              ? window.location.href
              : process.env.GATSBY_CLIENT_NAME
          }`
        )
        dispatch(setInvalidResults())
      })
  }
}

export const setProgress = (payload = true) => {
  return {
    type: SET_PROGRESS,
    payload,
  }
}

export const setInvalidResults = (payload = true) => {
  return {
    type: INVALID_RESULTS,
    payload,
  }
}

export const setActiveSpace = (payload = "") => {
  return {
    type: SET_ACTIVE_SPACE,
    payload,
  }
}

export const setHolidays = (payload = []) => {
  return {
    type: SET_HOLIDAYS,
    payload,
  }
}

export const setResults = (list, user) => {
  return {
    type: SET_RESULTS,
    list,
    user,
  }
}

export const setOperatingHours = (openingTime, closingTime) => {
  return {
    type: SET_OPERATING_HOURS,
    openingTime,
    closingTime,
  }
}
