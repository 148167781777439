// extracted by mini-css-extract-plugin
export var bodyFont = "index-module--body-font--+b0R5";
export var borderRadius = "index-module--border-radius--X5nER";
export var clearButtonStyles = "index-module--clear-button-styles--xLFmg";
export var express_checkout = "index-module--express_checkout--0BNyp";
export var express_checkout_disabled = "index-module--express_checkout_disabled--wETLu";
export var results_block = "index-module--results_block--jluTW";
export var results_block_description = "index-module--results_block_description--nnBOG";
export var results_block_image = "index-module--results_block_image--4HycU";
export var short_address = "index-module--short_address--w18fh";
export var titleFont = "index-module--title-font--YfeMm";