const priceRange = space => {
  if (!space || !space.results || space.results.length === 0) {
    return { rangeStart: "N/A", multiple: false }
  }

  const options = space.results

  let rangeStart = 0
  let multiple = false

  options.forEach(option => {
    if (rangeStart === 0) rangeStart = option.price_per_day
    else if (rangeStart !== option.price_per_day) {
      multiple = true
      if (rangeStart > option.price_per_day) {
        rangeStart = option.price_per_day
      }
    }
  })

  return {
    rangeStart,
    multiple,
  }
}
export default priceRange
