import React, { useEffect, useState } from "react"
import { cities } from "@config"
import * as styles from "./index.module.less"
import qs from "query-string"
import DateSelector from "@components/OnBoarding/DateSelector"
import TeamSizeSelector from "@components/OnBoarding/TeamSizeSelector"
import { format, isSameDay, parse } from "date-fns"
import updateQs from "./updateQs"

const defaultDateLabel = "Date"

const Layout = ({ children }) => {
  /**
   * This is the template of the results page
   * If the required params are found in the URL, this will display the results
   * If not, the user will be taken to /onboard
   * Until the required parameters are finalized, the page will display blank
   */

  const searchString =
    typeof window !== "undefined" ? window.location.search : ""

  const [activeDropDown, setActiveDD] = useState(null)

  const [city, setCity] = useState("")
  const [teamSize, setTeamSize] = useState(null)
  const [dateRange, setDateRange] = useState({ dateFrom: null, dateTo: null })

  const [dateLabel, setDateLabel] = useState(defaultDateLabel)

  useEffect(() => {
    if (typeof window === "undefined") return

    const params = qs.parse(searchString)

    // setting the filter values for the first time
    if (params.team_size) setTeamSize(params.team_size)
    if (params.location) setCity(params.location)

    window.addEventListener("click", () => setActiveDD(""))
    return () => {
      window.removeEventListener("click", () => setActiveDD(""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return

    const {
      team_size = null,
      location = null,
      start_date = null,
      end_date = null,
      start_time = null,
      end_time = null,
      on_demand_type = "Daily",
    } = qs.parse(searchString)

    if (team_size && team_size !== teamSize) setTeamSize(team_size)
    if (location && location !== city) setCity(location)

    if (
      on_demand_type === "Hourly" &&
      start_date &&
      end_date &&
      start_time &&
      end_time
    ) {
      const sd = parse(start_date, "yyyy-MM-dd", new Date())
      setDateLabel(`${format(sd, "MMM d")}, ${start_time} - ${end_time}`)
    } else if (on_demand_type === "Daily" && start_date && end_date) {
      const sd = parse(start_date, "yyyy-MM-dd", new Date())
      const ed = parse(end_date, "yyyy-MM-dd", new Date())
      if (isSameDay(sd, ed)) {
        setDateLabel(format(sd, "MMM d"))
      } else {
        setDateLabel(`${format(sd, "MMM d")} - ${format(ed, "MMM d")}`)
      }
    } else {
      setDateLabel(defaultDateLabel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString])

  useEffect(() => {
    if (city) updateQs("location", city)
  }, [city])

  useEffect(() => {
    if (teamSize) updateQs("team_size", teamSize)
  }, [teamSize])

  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  return (
    <>
      <div className={styles.search_params_container}>
        {cities.length > 1 && (
          <ul className={styles.cities}>
            {cities.map((location, idx) => (
              <li key={idx}>
                <button
                  className={
                    city === location ? "theme-city-button-active" : ""
                  }
                  onClick={() => setCity(location)}
                >
                  {location}
                </button>
              </li>
            ))}
          </ul>
        )}

        {params.res_type === "on-demand-offices" && (
          <ul className={styles.dropdown_filters}>
            <li>
              <button
                className={teamSize ? "theme-filter-active" : ""}
                onClick={e => {
                  e.stopPropagation()
                  setActiveDD("ts")
                }}
              >
                {teamSize
                  ? `${teamSize} ${
                      Number(teamSize) === 1 ? "Person" : "People"
                    }`
                  : "Seat Count"}
              </button>
              {activeDropDown === "ts" && (
                <TeamSizeSelector
                  teamSize={teamSize || ""}
                  className={"pb-4"}
                  dismissibleForMobiles={() => setActiveDD("")}
                  setTeamSize={value => {
                    updateQs("team_size", value)
                    setActiveDD("")
                  }}
                />
              )}
            </li>
            <li className={teamSize ? "" : styles.team_size_disabled}>
              <button
                className={
                  dateLabel !== defaultDateLabel ? "theme-filter-active" : ""
                }
                onClick={e => {
                  e.stopPropagation()
                  setActiveDD(teamSize ? "dp" : "")
                }}
              >
                {dateLabel}
              </button>
              {activeDropDown === "dp" && (
                <DateSelector
                  dateRange={dateRange}
                  dismissibleForMobiles={() => setActiveDD("")}
                  setDateRange={setDateRange}
                  closeFilter={() => setActiveDD("")}
                  city={city}
                  dismissible={
                    dateRange.dateFrom
                      ? () => {
                          setDateRange({ dateFrom: null, dateTo: null })
                          setActiveDD("")
                        }
                      : null
                  }
                />
              )}
            </li>
          </ul>
        )}
      </div>
    </>
  )
}

export default Layout
