import React from "react"
import SearchParams from "../SearchParams"
import ReservationTypes from "../ReservationTypes"
import ResultsBlock from "../ResultsBlock"
import * as styles from "./index.module.less"
import ProgressDots from "@components/ProgressDots"
import Footer from "../../Footer"
import qs from "query-string"
import { useSelector } from "react-redux"

const ResultsContainer = () => {
  const list = useSelector(state => state.results.list)
  const inProgress = useSelector(state => state.results.inProgress)



  return (
    <div
      className={`${styles.results_list_wrapper} ${
        !inProgress ? "" : styles.progress
      }`}
    >
      <ReservationTypes />
      <SearchParams />
      {inProgress ? (
        <Progress />
      ) : (
        <>{list.length > 0 ? <Results list={list} /> : <NoResults />}</>
      )}
      <Footer />
    </div>
  )
}

export default ResultsContainer

const Results = ({ list, spaceUserId }) => {
  return (
    <>
      <div className={styles.results_list}>
        {list.map(space => {
          return (
            <ResultsBlock
              space={space}
              key={space.space_id}
              spaceUserId={spaceUserId}
            />
          )
        })}
      </div>
    </>
  )
}

const Progress = () => {
  return <ProgressDots active />
}

const NoResults = () => {
  return (
    <div className={styles.no_results_wrapper}>
      <h3>No results found.</h3>
      <p>Try editing your search by changing team size or date range.</p>
    </div>
  )
}
