import React, { useEffect, useState } from "react"
import * as styles from "./index.module.less"
import BlockLayout from "../BlockLayout"
import Counter from "@components/Counter"
import qs from "query-string"

const OnDemandOfficeBlock = ({ space }) => {
  const [count, setCount] = useState(1)

  console.log(space)

  useEffect(() => {
    if (typeof window !== "undefined") return
    const params = qs.parse(window.location.search)

    if (params.team_size && Number(params.team_size) < 11) {
      setCount(Number(params.team_size))
    }
  }, [])

  return (
    <BlockLayout
      space={space}
      expressCheckout={true}
      expressCheckoutActive={count > 0}
      count={count}
    >
      <Counter
        count={count}
        setCount={setCount}
        className={`d-none d-sm-block ${styles.counter}`}
        max={space?.day_passes_per_day || 10}
      />

      <p className={`${styles.price_per_day_pass}`}>
        ${space.price_per_day_pass}/pass
      </p>
    </BlockLayout>
  )
}

export default OnDemandOfficeBlock
