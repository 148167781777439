import React, { useEffect } from "react"
import Layout from "@layouts/Results"
import { navigate } from "gatsby"
import Seo from "@components/seo"
import fetchHolidays from "@utils/fetchHolidays"
import isUUID from "validator/es/lib/isUUID"
import { cities, onDemandTypes } from "@config"
import qs from "query-string"
import { useDispatch } from "react-redux"
import { setHolidays, setOperatingHours } from "../stores/ResultsStore/actions"
import request from "../services/request"
import { get } from "lodash"

const HomePage = ({ location, ...props }) => {
  const dispatch = useDispatch()
  /**
   * Fetch results if the required parameters are found
   */

  useEffect(() => {
    if (
      process.env.GATSBY_SPACE_UUID &&
      isUUID(process.env.GATSBY_SPACE_UUID)
    ) {
      navigate(
        `/space/${process.env.GATSBY_SPACE_UUID}/?res_type=on-demand-offices`
      )
      return
    } else {
      fetchHolidays().then(res => {
        dispatch(setHolidays(res))
      })
      request("so/view", {
        id: process.env.GATSBY_CLIENT_UUID,
      })
        .then(res => {
          const opening_time = get(res, "data.soData.opening_time", "9:00 AM")
          const closing_time = get(res, "data.soData.opening_time", "5:00 PM")
          if (res && res.data && res.data.soData) {
            dispatch(setOperatingHours(opening_time, closing_time))
          }
        })
        .catch(e => {
          console.log(e)
        })
    }
    const params = qs.parse(location.search)
    if (params && params.location && cities.indexOf(params.location) === -1) {
      navigate(
        `/?res_type=on-demand-offices&on_demand_type=${onDemandTypes[0]}&location=${cities[0]}`
      )
    } else if (!location.search) {
      navigate(
        `/?res_type=on-demand-offices&on_demand_type=${onDemandTypes[0]}&location=${cities[0]}`
      )
    } else if (
      location.search &&
      params.res_type === "on-demand-offices" &&
      !params.location
    ) {
      navigate(
        `/?res_type=on-demand-offices&on_demand_type=${onDemandTypes[0]}&location=${cities[0]}`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="ws-hidden-overflow-wrapper">
      <Layout>
        <Seo
          title={`${process.env.GATSBY_CLIENT_NAME} | Book offices on-demand`}
          description={`Book offices on-demand`}
        />
      </Layout>
    </div>
  )
}

export default HomePage
