import React from "react"
import PropTypes from "prop-types"
import DayPassBlock from "./DayPassBlock"
import OnDemandOfficeBlock from "./OnDemandOfficeBlock"
import qs from "query-string"

const ResultBlock = ({ space }) => {
  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  if (params.res_type === "day-passes") {
    return <DayPassBlock space={space} />
  }

  return <OnDemandOfficeBlock space={space} teamSize={params.team_size} />
}

export default ResultBlock

ResultBlock.propTypes = {
  space: PropTypes.object,
  spaceUserId: PropTypes.string,
}
