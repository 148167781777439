import React from "react"
import PropTypes from "prop-types"
import BlockLayout from "../BlockLayout"
import * as styles from "./index.module.less"
import { useSelector } from "react-redux"

const OnDemandOfficeBlock = ({ space }) => {
  const user = useSelector(state => state.results.user)

  const onDemandDaysHoursCount = useSelector(
    state => state.profile.onDemandDaysHoursCount
  )
  const isPerDayOnDemand = useSelector(state => state.profile.isPerDayOnDemand)

  let price = space.price_per_day

  if (space && space.results && space.results[0]) {
    if (isPerDayOnDemand) {
      price = space.results[0].price_per_day
    } else {
      price = space.results[0].price_per_hour
    }
  }

  return (
    <BlockLayout space={space}>
      <SummaryList space={space} user={user} />
      <span className={styles.price}>
        ${price}/{isPerDayOnDemand ? "day" : "hr"}
        {onDemandDaysHoursCount > 1 && (
          <>
            <br />
            <span>
              ${(price * onDemandDaysHoursCount).toLocaleString("en-US")} total
            </span>
          </>
        )}
      </span>
    </BlockLayout>
  )
}

export default OnDemandOfficeBlock

const SummaryList = ({ space = {}, user = {} }) => {
  if (user.team_size === "0") return null
  return (
    <p className={styles.summary_list}>
      <span>Private office</span>

      {space.results && space.results.length > 1 && (
        <span>{space.results.length} options</span>
      )}

      {space.type === "office" && space.capacity && (
        <span>
          Up to {space.capacity} {space.capacity === 1 ? "person" : "people"}
        </span>
      )}
    </p>
  )
}

SummaryList.propTypes = {
  space: PropTypes.object,
  user: PropTypes.object,
}
