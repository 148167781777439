import qs from "query-string"

const resultBlockUrl = (space = {}, expressCheckout = false, teamSize = 0) => {
  if (!space.space_id) {
    return null
  }
  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  return `/space/${space.space_id}?${qs.stringify(params)}${
    expressCheckout ? "&express_checkout=✓" : ""
  }${teamSize > 0 ? `&team_size=${teamSize}` : ""}`
}
export default resultBlockUrl
