import React, { useEffect, useState } from "react"
import qs from "query-string"
import * as styles from "./index.module.less"
import { refreshResults } from "../../stores/ResultsStore/actions"
import { useDispatch, useSelector } from "react-redux"
import ResultsList from "./ResultsList"
import ResultsMap from "./ResultsMap"
import countWorkingDays from "../../utils/countWorkingDays"
import { parse } from "date-fns"
import { isSingleSpace } from "@utils/config"
import isUUID from "validator/es/lib/isUUID"
import { setValues } from "../../stores/ProfileStore/actions"

const Layout = ({ children }) => {
  const [logo, setLogo] = useState("")
  const [bgDesktop, setBgDesktop] = useState("")
  const [bgTablet, setBgTablet] = useState("")
  const [bgMobile, setBgMobile] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    fetchLogo()
    fetchBgImages()
  }, [])

  const fetchLogo = () => {
    import(
      `@images/clients/${
        !process.env.GATSBY_SPACE_UUID
          ? process.env.GATSBY_CLIENT_SLUG
          : "default"
      }/logo-light.svg`
    )
      .then(image => setLogo(image.default))
      .catch(e => {
        import(`@images/clients/default/logo-light.svg`).then(image =>
          setLogo(image.default)
        )
      })
  }
  const fetchBgImages = () => {
    import(
      `@images/clients/${
        !process.env.GATSBY_SPACE_UUID
          ? process.env.GATSBY_CLIENT_SLUG
          : "default"
      }/results-bg-desktop.jpg`
    )
      .then(image => setBgDesktop(image.default))
      .catch(e => {
        import(`@images/clients/default/results-bg-desktop.jpg`).then(image =>
          setBgDesktop(image.default)
        )
      })
    import(
      `@images/clients/${
        !process.env.GATSBY_SPACE_UUID
          ? process.env.GATSBY_CLIENT_SLUG
          : "default"
      }/results-bg-tablet.jpg`
    )
      .then(image => setBgTablet(image.default))
      .catch(e => {
        import(`@images/clients/default/results-bg-tablet.jpg`).then(image =>
          setBgTablet(image.default)
        )
      })
    import(
      `@images/clients/${
        !process.env.GATSBY_SPACE_UUID
          ? process.env.GATSBY_CLIENT_SLUG
          : "default"
      }/results-bg-mobile.jpg`
    )
      .then(image => setBgMobile(image.default))
      .catch(e => {
        import(`@images/clients/default/results-bg-mobile.jpg`).then(image =>
          setBgMobile(image.default)
        )
      })
  }
  /**
   * This is the template of the results page
   * If the required params are found in the URL, this will display the results
   * If not, the user will be taken to /onboard
   * Until the required parameters are finalized, the page will display blank
   */
  const windowSearch =
    typeof window !== "undefined" ? window.location.search : ""

  const [pageReady, setPr] = useState(false)

  const holidays = useSelector(state => state.results.holidays)

  const [resType, setResType] = useState("On-demand Offices")

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = qs.parse(windowSearch)
      dispatch(
        setValues({
          isPerDayOnDemand: searchParams.on_demand_type !== "Hourly",
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // check the URL params and set page status

    if (typeof window !== "undefined") {
      const searchParams = qs.parse(windowSearch)
      if (searchParams.res_type === "day-passes") {
        setResType("Day Passes")
      } else {
        setResType("On-demand Offices")
      }
      if (
        process.env.GATSBY_SPACE_UUID &&
        isUUID(process.env.GATSBY_SPACE_UUID)
      ) {
        // navigate(`/space/${process.env.GATSBY_SPACE_UUID}`)
        // do nothing
      } else {
        setPr(true)
      }

      if (searchParams.move_in_date && searchParams.move_out_date) {
        const days = countWorkingDays(
          parse(searchParams.move_in_date, "yyyy-MM-dd", new Date()),
          parse(searchParams.move_out_date, "yyyy-MM-dd", new Date()),
          holidays[searchParams.location] || []
        )
        dispatch(
          setValues({
            days,
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSearch, holidays])

  useEffect(() => {
    if (windowSearch && pageReady) {
      dispatch(refreshResults())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSearch, pageReady])

  const [mobileImage, setMobileImage] = useState(false)
  const [tabletImage, setTabletImage] = useState(false)
  const [desktopImage, setDesktopImage] = useState(false)

  if (!pageReady || isSingleSpace) return null

  return (
    <>
      <div className={styles.header}>
        <>
          <img
            src={bgMobile}
            alt=""
            className={`d-sm-none ${mobileImage ? styles.isVisible : ""}`}
            onLoad={() => setMobileImage(true)}
          />
          <img
            src={bgTablet}
            alt=""
            className={`d-none d-sm-block d-lg-none ${
              tabletImage ? styles.isVisible : ""
            }`}
            onLoad={() => setTabletImage(true)}
          />
          <img
            src={bgDesktop}
            alt=""
            className={`d-none d-lg-block ${
              desktopImage ? styles.isVisible : ""
            }`}
            onLoad={() => setDesktopImage(true)}
          />
        </>
        <div className="container-expand">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <a href={"/"}>
                {logo && <img src={logo} alt="" className={styles.logo} />}
              </a>
            </div>
          </div>
        </div>
        <div className="container-expand">
          <div className={styles.page_heading}>
            <h1>{resType}</h1>
          </div>
        </div>
      </div>
      <div className={styles.results_wrapper}>
        <ResultsList />
        <ResultsMap />
      </div>
      <div className={"d-none"}>{children}</div>
    </>
  )
}

export default Layout
