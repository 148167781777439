import React, { useState } from "react"
import resultBlockUrl from "@utils/resultBlockUrl"
import useOptimizedImages from "@utils/useOptimizedImages"
import * as styles from "./index.module.less"
import replace from "@utils/replace"
import { setActiveSpace } from "../../../../stores/ResultsStore/actions"
import { useDispatch } from "react-redux"

const BlockLayout = ({
  children,
  space,
  expressCheckout = false,
  expressCheckoutActive = false,
  count = 0,
}) => {
  const dispatch = useDispatch()

  const spaceImage = useOptimizedImages(
    space.image || space.cover_image,
    700,
    600,
    600,
    600
  )

  const [chromeInIos] = useState(
    typeof navigator !== "undefined" &&
      navigator.userAgent.indexOf("CriOS") >= 0
  )

  return (
    <div className={styles.results_block}>
      <a
        href={resultBlockUrl(space, false, count)}
        target={chromeInIos ? "_self" : "_blank"}
        rel="noopener noreferrer"
        onMouseEnter={() => dispatch(setActiveSpace(space.space_id))}
        onMouseLeave={() => dispatch(setActiveSpace())}
      >
        <div className={styles.results_block_image}>
          <img src={spaceImage} alt={space.name} />
        </div>
        <div className={styles.results_block_description}>
          {space.location && space.location.short_address && (
            <p className={styles.short_address}>
              {space.location.short_address}
            </p>
          )}
          {space.name && <h3 className="name">{replace(space.name)}</h3>}
          {children}
        </div>
      </a>
      {expressCheckout && (
        <>
          {expressCheckoutActive ? (
            <a
              href={resultBlockUrl(space, true, count)}
              className={`${styles.express_checkout} ${
                expressCheckoutActive ? "" : styles.express_checkout_disabled
              }`}
              target={chromeInIos ? "_self" : "_blank"}
              rel={chromeInIos ? "" : "noreferrer"}
            >
              Express Check-out
            </a>
          ) : (
            <span
              className={`${styles.express_checkout} ${styles.express_checkout_disabled}`}
            >
              Express Check-out
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default BlockLayout
