import React from "react"
import { cities, resTypes } from "@config"
import { navigate } from "gatsby"
import qs from "query-string"
import * as styles from "./index.module.less"

const ReservationTypes = () => {
  const params =
    typeof window !== "undefined" ? qs.parse(window.location.search) : {}

  const setType = type => {
    if (params.res_type !== type) {
      if (type === "on-demand-offices") {
        navigate(
          `/?res_type=${type}&on_demand_type=Daily&location=${cities[0]}`
        )
      } else {
        navigate(`/?res_type=${type}&location=${cities[0]}`)
      }
    }
  }

  if (resTypes.length === 1) return null

  return (
    <div className={styles.res_type_wrapper}>
      <ul>
        {resTypes.map(({ name, slug }) => (
          <li key={slug}>
            <button
              onClick={() => setType(slug)}
              className={params.res_type === slug ? styles.active_res_type : ""}
            >
              {name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ReservationTypes
